import gql from 'graphql-tag'
import { useParams, useHistory } from 'react-router-dom'
import { useState, useEffect, useContext, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { get } from 'lodash'
import { Navbar, Button, Toast, Stack } from '@collegebacker/backer-ui/ui'

import { createPlaidLinkToken, loadPlaidLink, openPlaid } from 'utils/plaid'
import { backToDashboard, isMobileApp } from 'utils/helpers'
import { linkOutOfAppForCurrentPage } from 'utils/mobile-app-publisher'
import { SessionContext } from 'context/SessionProvider'
import { buildPath } from 'routes'
import config from 'config'

import styles from './VerifyBankAccount.module.scss'

const LINK_BANK_ACCOUNT = gql`
  mutation LinkBankAccount($input: LinkBankAccountInputType!) {
    linkBankAccount(input: $input) {
      id
      accountNumber
      bankLabel
      accountLabel
      removable
      removableReason
    }
  }
`

const FAIL_BANK_ACCOUNT_VERIFICATION = gql`
  mutation FailBankAccountVerification(
    $input: FailBankAccountVerificationInputType!
  ) {
    failBankAccountVerification(input: $input) {
      success
    }
  }
`

export const VerifyBankAccount = () => {
  const { bankAccountUuid }: { bankAccountUuid: string } = useParams()
  const context = useContext(SessionContext)
  const history = useHistory()
  const [alertMessage, setAlertMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const toastRef = useRef<ToastRef>(null)
  const [linkBankAccount] = useMutation(LINK_BANK_ACCOUNT)
  const [failBankAccountVerification] = useMutation(FAIL_BANK_ACCOUNT_VERIFICATION)

  const displayPlaidPopup = async () => {
    const { linkToken } = await createPlaidLinkToken({
      products: [],
      bankAccountUuid
    })

    const onVerificationFailed = () => {
      failBankAccountVerification({
        variables: { input: { bankAccountUuid } }
      })
        .then(() =>
          backToDashboard(
            context,
            'There was a problem verifying your bank details.'
          )
        )
        .catch((error) => setAlertMessage(JSON.stringify(error, null, 4)))
    }

    const onVerificationSuccess = (publicToken: string, metadata: any) => {
      const accountMetadata = get(metadata, 'accounts[0]', {})
      linkBankAccount({
        variables: {
          input: {
            bankAccountUuid,
            accountId: accountMetadata.id,
            verificationStatus: accountMetadata.verification_status,
            bankName: get(metadata, 'institution.name') || 'Bank Account',
            publicToken
          }
        }
      })
        .then(() => {
          setSuccess(true)
        })
        .catch((error) => setAlertMessage(JSON.stringify(error, null, 4)))
    }

    openPlaid({
      webClientSettings: {
        token: linkToken,
        onSuccess: onVerificationSuccess,
        onEvent: (eventName: string, metadata: any) => {
          if (
            eventName === 'EXIT' &&
            metadata.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS'
          ) {
            onVerificationFailed()
          }
        }
      }
    })
  }

  useEffect(() => loadPlaidLink(), [])

  useEffect(() => {
    if (alertMessage !== '' && toastRef.current) {
      toastRef.current.showToast(alertMessage, {
        type: 'error',
        closeOnClick: false,
        showCloseIcon: true,
        onClose: () => setAlertMessage('')
      })
    }
  }, [alertMessage])

  const backButtonClick = () => history.push(buildPath('editPaymentMethods'))

  if (success)
    return (
      <>
        {!isMobileApp() && <Navbar backButtonClick={backButtonClick} />}
        <section className={styles.container}>
          <div className={styles.screenHeaderContainer}>
            <h1 className="typo-app-title-large">Bank Account Verification</h1>
            <p className="typo-app-body-paragraph">
              Account verified successfully 🎉
            </p>
          </div>
        </section>
      </>
    )

  return (
    <>
      {!isMobileApp() && <Navbar backButtonClick={backButtonClick} />}
      <Toast ref={toastRef} />
      <section className={styles.container}>
        <div className={styles.screenHeaderContainer}>
          <h1 className="typo-app-title-large">Bank Account Verification</h1>
          <p className="typo-app-body-paragraph">
            To complete your setup, please verify your bank account. This helps
            us securely connect your bank to streamline future transactions.
          </p>
        </div>

        {isMobileApp() ? (
          <p>
            This feature is not available yet on our mobile app but you can{' '}
            <a
              href={linkOutOfAppForCurrentPage()}
              style={{ textDecoration: 'underline' }}
            >
              click here to open this page in a browser
            </a>
            .
          </p>
        ) : (
          <Stack
            spacing={20}
            style={{ maxWidth: '400px', width: '100%' }}
          >
            <Button
              className={styles.verifyButton}
              mode="outline"
              maxWidth={400}
              label="Verify your bank account"
              onClick={displayPlaidPopup}
            />
            <Button
              tag="a"
              rel="noopener noreferrer"
              href={config.HELP_URL}
              target="_blank"
              label="Need help? Contact support"
              mode="ghost"
              minWidth={335}
            />
          </Stack>
        )}
      </section>
    </>
  )
}
